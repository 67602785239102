// FloatingWindowContext.js
import React, { createContext, useState,useRef, useEffect } from 'react';
// import {FloatingWindow} from './FloatingWindow';

const FloatingWindowContext = createContext();

// 角度转换为弧度
const degreesToRadians = (degrees) => degrees * (Math.PI / 180);
// 初始角度为60度
let angle = degreesToRadians(60);
// 速度和边界检查
const speed = 2; // 像素/帧
const screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;
const windowWidth = 300; // 飘窗宽度
const windowHeight = 180; // 飘窗高度
let directionY = 0 // Y轴方向向下；1：向上
let directionX = 0 // X轴方向右；1：向左

const getRandomFloat=(min,max)=>Math.random()*(max-min)+min;

const FloatingWindowProvider = ({ children }) => {
    const [position, setPosition] = useState({ x: getRandomFloat(0,screenWidth), y: getRandomFloat(0,screenHeight) });
    // const [position, setPosition] = useState({ x: 0, y: 0 });
    const [moving, setMoving] = useState(true);

    useEffect(() => {
        let frameId;

        const moveWindow = () => {
            if (!moving) return;
// return
            // 计算新位置
            let newX = position.x;
            let newY = position.y;


            //设置图片在Y轴上的移动规律
            if(directionY == 0) {
                //飘浮图片在Y轴方向上向下移动
                newY += speed;
            } else {
                //飘浮图片在Y轴方向上向上移动
                newY -= speed;
            }
            if(newY < 0) {
                //如果飘浮图片飘浮到顶端的时候，设置图片在Y轴方向上向下移动
                directionY = 0;
                newY = 0;
            }
            if(newY + windowHeight >= screenHeight) {
                //如果飘浮图片飘浮到浏览器底端的时候，设置图片在Y轴方向上向上移动
                directionY = 1;
                // newY = (height - imgHeight-10);
            }
            //设置图片在X轴上的移动规律
            if(directionX == 0) {
                newX += speed;
            } else {
                newX -= speed;
            }
            if(newX < 0) {
                //如果飘浮图片飘浮到浏览器左侧的时候，设置图片在X轴方向上向右移动
                directionX = 0;
                newX = 0;
            }
            if(newX + windowWidth >= screenWidth) {
                //如果飘浮图片飘浮到浏览器右侧的时候，设置图片在X轴方向上向左移动
                directionX = 1;
                // newX = (width - imgWidth);
            }


            // 更新位置
            setPosition({ x: newX, y: newY });

        };

        // 开始动画
        frameId = requestAnimationFrame(moveWindow);

        // 清理函数
        return () => {
            cancelAnimationFrame(frameId);
        };
    }, [position, moving]);

    return (
        <FloatingWindowContext.Provider value={{ position, setPosition,moving, setMoving }}>
            {children}
        </FloatingWindowContext.Provider>
    );
};

export { FloatingWindowContext, FloatingWindowProvider };