// FloatingWindow.js
import React, {useContext, useRef} from 'react';
import {Card} from 'antd';
import xibao from "../../assets/img/xibao.png"
import closeIcon from "../../assets/img/close.png"

import {FloatingWindowContext} from './FloatingWindowContext';
import './FloatingWindow.css';

const FloatingWindow = () => {
    const {position, setPosition, moving, setMoving} = useContext(FloatingWindowContext);
    const closeRef = useRef(null);
    const {Meta} = Card;
    const handleCloseClick = () => {
        document.querySelector('.floating-window').style.display = 'none';
    };
    const handleHref = () => {
        window.open('https://mp.weixin.qq.com/s/tuBrbZny4vJKKk5i_9RwIQ')
    }
    const handleMouseOver = () => {
        setMoving(false)
    }

    const handleMouseOut = () => {
        setMoving(true)
    }
    return (
        <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut}
             className="floating-window"
             style={{
                 top: `${position.y}px`,
                 left: `${position.x}px`,
             }}
        >
            <div className="close-button" onClick={handleCloseClick}>
                <img src={closeIcon} alt="close"/>
            </div>

            <img onClick={handleHref}
                 style={{
                     width: '100%',
                     borderRadius: '5px 5px 0 0'
                 }}
                 src={xibao}/>


        </div>

    );
};

export default FloatingWindow;