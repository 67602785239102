import React from 'react';
// import logo from '@/logo.png';

export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper',id:'header' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: process.env.SOURCE_URL+'/logo.png',//logo,
  },
  Menu: {
    className: 'header0-menu',
    children: [
      { name: 'item0', title: '首页', href: '/' },
      {
        name: 'item1', title: 'CanBeauty系列', href: '/canbeauty',
        children: [
          { name: 'item1', title: '了解CanBeauty', href: '/canbeauty' },
          { name: 'item1', title: '口红质检', href: '/lipstick' },
          { name: 'item1', title: '瓶底喷码质检', href: '/jetcode' },
          { name: 'item1', title: 'CSAR', href: '/csar' },
        ],
      },
      {
        name: 'item2', title: 'CanSee系列', href: '/cansee',
        children: [
          // { name: 'item1', title: '了解CanSee', href: '/abc2' },
          // { name: 'item1', title: '手术耗材识别', href: '/abc3' },
          // { name: 'item1', title: '货物识别分拣', href: '/abc4' },
          // { name: 'item1', title: '医师考试', href: '/abc5' },
          { name: 'item1', title: '可穿戴安防', href: '/security' },
        ],
      },
      {
        name: 'item3', title: 'CanEnergy系列',  href: '/canenergy',
        children: [
          { name: 'item1', title: '了解CanEnergy', href: '/energy' },
          { name: 'item1', title: '太阳能板质检', href: '/solarpv' },
          { name: 'item1', title: '核电运维', href: '/nuclear' },
        ],
      },
      {
        name: 'item3', title: '关于我们', href: '/contact',
        children: [
          { name: 'item1', title: '联系我们', href: '/contact' },
          // { name: 'item1', title: '可明管理', href: '#' },
        ]
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};

export const Footer11DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            process.env.SOURCE_URL+'/logo.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: '不止算法，我们是集成商',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: '产品' },
        childWrapper: {
          children: [
            { name: 'link0', href: '/canbeauty', children: 'CanBeauty系列' },
            { name: 'link1', href: '/security', children: 'CanSee系列' },
            { name: 'link2', href: '/energy', children: 'CanEnergy系列' },
            { name: 'link3', href: '/contact', children: '其他' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            { href: '/contact', name: 'link0', children: '售前支持' },
            { href: '#', name: 'link1', children: '加入我们' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: '联系方式' },
        childWrapper: {
          children: [
            { href: 'mailto:ai@aicalming.com', name: 'link0', children: 'ai@aicalming.com' },
            { href: 'mailto:hr@calming.com.cn', name: 'link0', children: 'hr@calming.com.cn' },
          ],
        },
      },
      {
        name: 'qrcode',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: '关注' },
        childWrapper: {
          className: 'qrcode',
          children: [
            { href: '#', name: 'image',className:'footer-img' ,children: process.env.SOURCE_URL+'/kmai-qrcode.jpeg' },
            { href: '#', name: 'image', className:'footer-img' ,children: process.env.SOURCE_URL+'/km-qrcode.jpeg' },
          ],
        },
      },
    ],

  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2022-2024 by <a href="/">CALMING AI</a> All Rights Reserved
      </span>
    ),
  },
};

export const Footer21DataSource = {
  wrapper: { className: ' footer2-wrapper' },
  OverPack: { className: ' footer2', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: [

      {
        name: 'group',
        children: '东华大学',
        className: 'copyright-group',
        href:'https://meccol.dhu.edu.cn',
      },
      {
        name: 'image2',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/fgGmQUfiUfSBfvsQpfOj.svg',
        className: 'copyright-line',
      },
      {
        href:'http://calming.com.cn',
        name: 'copyright',
        children: '可明信息',
        className: 'copyright-text',
      },
      // {
      //   name: 'image2',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/fgGmQUfiUfSBfvsQpfOj.svg',
      //   className: 'copyright-line',
      // },
      // {
      //   href:'#',
      //   name: 'copyright',
      //   children: '沪ICP备17007010号',
      //   className: 'copyright-text',
      // },
    ],
  },
};
